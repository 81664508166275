
.task_settings_item_form {
  &_name {
    input {
      box-sizing: border-box !important;
    }
  }

  .truncate {
    max-width: 178px;
    span {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */
      text-align: right;
      color: #EDEFF1;
      transition: 300ms margin ease, 300ms padding ease;
      background: transparent;
      padding-left: 0;
    }
  }

}
