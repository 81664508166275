.task_settings_pomodoro_display {
  margin-right: 9px;
  .digit {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-align: right;

    color: #edeff1;
  }
  .min {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #a7afb9;
  }
}
