@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap");
@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Light.woff2") format("woff2"), url("./assets/fonts/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Regular.woff2") format("woff2"), url("./assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Bold.woff2") format("woff2"), url("./assets/fonts/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

*,
*::after,
*::before {
  box-sizing: border-box; }

* {
  margin: 0;
  padding: 0; }

html {
  font-family: 'Montserrat', sans-serif; }

body {
  margin: 0;
  padding: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.tippy-tooltip {
  border-radius: 3px;
  background-color: #4b5a6c;
  padding: 8px; }
  .tippy-tooltip .tippy-tooltip-content .button:hover {
    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2); }

.tippy-popper[x-placement^='bottom'] [x-arrow] {
  border-bottom-color: #4b5a6c; }

.tippy-popper[x-placement^='left'] [x-arrow] {
  border-left-color: #ffffff; }

.tippy-popper[x-placement^='tirght'] [x-arrow] {
  border-right-color: #4b5a6c; }

.flex {
  display: flex;
  flex-wrap: wrap; }

.flex-v {
  flex-direction: column; }

h1,
h2,
h3 {
  font-family: Lato;
  font-style: normal;
  color: #ffffff; }

h1 {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px; }

h2 {
  font-weight: normal;
  font-size: 27px;
  line-height: 32px;
  color: #edeff1; }

h3 {
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #cacfd5; }

p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #cacfd5; }
  p.text_big {
    font-size: 18px;
    line-height: 22px; }

small {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #a7afb9; }

a,
.link {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #41acd7;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  -webkit-transition: 200ms color ease;
  transition: 200ms color ease; }
  a:after,
  .link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0px;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    background-color: #41acd7;
    -webkit-transition: 200ms height ease;
    transition: 200ms height ease; }
  a:visited,
  .link:visited {
    text-decoration: underline;
    color: #7b93d1; }
  a:hover,
  .link:hover {
    color: #58b3d8; }
    a:hover:after,
    .link:hover:after {
      height: 50%;
      background-color: #58b3d8; }
  a:active,
  .link:active {
    color: #58b3d8; }
    a:active:after,
    .link:active:after {
      height: 100%;
      background-color: #58b3d8; }

h3 a {
  font-size: 22px;
  line-height: 26px; }

p a {
  font-size: 15px;
  line-height: 18px; }

.label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #abb3bc;
  background: rgba(88, 179, 216, 0.2);
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer; }
  .label .icon_closex path {
    fill: #6b9fb9;
    -webkit-transition: 300ms fill ease;
    transition: 300ms fill ease; }
  .label:hover .icon_closex path {
    fill: #ffffff; }

.preloader {
  -webkit-animation-name: preloader;
          animation-name: preloader;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes preloader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  75% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.button_socials, .button.google, .button.facebook, .button.apple {
  position: relative;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 224px;
  height: 44px;
  border: none;
  border-radius: 6px;
  -webkit-transition: 200ms opacity ease;
  transition: 200ms opacity ease; }
  .button_socials:hover, .button.google:hover, .button.facebook:hover, .button.apple:hover {
    opacity: 0.8; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #edeff1;
  border-radius: 3px;
  width: 160px;
  height: 40px;
  padding: 0 0 1px;
  background-color: transparent;
  color: #eaeef0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 300ms color ease, 300ms background-color ease, 300ms border-color ease;
  transition: 300ms color ease, 300ms background-color ease, 300ms border-color ease; }
  .button:hover {
    background-color: #53829b;
    color: #edeff1; }
    .button:hover span {
      color: #edeff1; }
  .button:focus {
    outline: none; }
  .button:active, .button.active, .active .button, .controls .button.time, .controls .button.money,
  .controls_primary .button.time,
  .controls_primary .button.money {
    background-color: #55a8cb; }
    .button:active.borderless, .button.active.borderless, .active .borderless.button, .controls .borderless.button.time, .controls .borderless.button.money,
    .controls_primary .borderless.button.time,
    .controls_primary .borderless.button.money {
      border-color: #55a8cb; }
    .button:active span, .button.active span, .active .button span, .controls .button.time span, .controls .button.money span, .controls_primary .button.time span, .controls_primary .button.money span {
      color: #edeff1; }
  .button[disabled] {
    color: #84909d;
    border-color: #84909d;
    cursor: default; }
    .button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active, .button[disabled].active, .active .button[disabled], .controls .button.time[disabled], .controls .button.money[disabled],
    .controls_primary .button.time[disabled],
    .controls_primary .button.money[disabled] {
      color: #84909d;
      border-color: #84909d;
      background-color: transparent; }
  .button span {
    color: #a7afb9;
    -webkit-transition: 300ms color ease;
    transition: 300ms color ease; }
  .button.google {
    background-color: #dc4e41; }
    .button.google:disabled {
      background: rgba(220, 78, 65, 0.6); }
  .button.facebook {
    background: #4b6fbc; }
    .button.facebook:disabled {
      background: rgba(220, 78, 65, 0.6); }
  .button.apple {
    background: #1d1d1d; }
    .button.apple:disabled {
      background: rgba(220, 78, 65, 0.6); }

.button_nav {
  border: none;
  background: none;
  position: relative;
  cursor: pointer; }
  .button_nav svg path {
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease;
    fill: #fff; }
  .button_nav svg:active path,
  .button_nav svg.active path {
    fill: #58b3d8; }
    .button_nav svg:active path.background,
    .button_nav svg.active path.background {
      fill-opacity: 0.2; }
  .button_nav svg.delete path {
    fill-opacity: 0; }
  .button_nav:hover svg path {
    fill: #58b3d8; }
    .button_nav:hover svg path .background {
      fill-opacity: 0; }
  .button_nav:hover .delete path {
    stroke: #cd2014; }
  .button_nav:focus {
    outline: none; }

button:disabled .check_icon {
  background-color: #4b5a6c; }

.check_icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 22px;
  background: #55a8cb;
  padding: 7px 5px 6px; }

.button_next,
.button_prev,
.button_edit {
  min-width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 22px;
  -webkit-transition: 200ms background-color ease;
  transition: 200ms background-color ease; }
  .button_next:hover,
  .button_prev:hover,
  .button_edit:hover {
    opacity: 1;
    background: rgba(88, 179, 216, 0.3); }
    .button_next:hover svg path,
    .button_prev:hover svg path,
    .button_edit:hover svg path {
      fill: #ffffff; }
  .button_next:focus,
  .button_prev:focus,
  .button_edit:focus {
    outline: none; }
  .button_next.active, .button_next:active,
  .button_prev.active,
  .button_prev:active,
  .button_edit.active,
  .button_edit:active {
    opacity: 1;
    background: #55a8cb; }
    .button_next.active svg path, .button_next:active svg path,
    .button_prev.active svg path,
    .button_prev:active svg path,
    .button_edit.active svg path,
    .button_edit:active svg path {
      fill: #ffffff; }
  .button_next svg path,
  .button_prev svg path,
  .button_edit svg path {
    -webkit-transition: 200ms fill ease;
    transition: 200ms fill ease; }

.button_check {
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 22px;
  -webkit-transition: 200ms background-color ease;
  transition: 200ms background-color ease; }
  .button_check:hover {
    opacity: 1;
    background: rgba(88, 179, 216, 0.3); }
    .button_check:hover svg path {
      fill: #ffffff; }
  .button_check:focus {
    outline: none; }
  .button_check.active, .button_check:active {
    opacity: 1;
    background: #55a8cb; }
    .button_check.active svg path, .button_check:active svg path {
      fill: #ffffff; }
  .button_check * {
    position: absolute; }

.controls,
.controls_primary {
  display: flex;
  flex-flow: row nowrap; }
  .controls .button,
  .controls_primary .button {
    padding: 7px 12px;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    border-radius: 0;
    border-left-width: 0px; }
    .controls .button:first-child,
    .controls_primary .button:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-left-width: 1px; }
    .controls .button:last-child,
    .controls_primary .button:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  .controls.disabled button,
  .controls_primary.disabled button {
    border-color: #84909d; }
    .controls.disabled button:not(.active),
    .controls_primary.disabled button:not(.active) {
      color: #84909d; }
      .controls.disabled button:not(.active):hover, .controls.disabled button:not(.active):active,
      .controls_primary.disabled button:not(.active):hover,
      .controls_primary.disabled button:not(.active):active {
        background-color: transparent;
        cursor: default; }

.controls_secondary .button {
  border-color: #7d8c9e;
  color: #cacfd5;
  width: auto;
  width: initial;
  box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2), inset 0px 0px 4px rgba(99, 180, 215, 0.2); }
  .controls_secondary .button.active, .controls_secondary .active .button, .active .controls_secondary .button, .controls_secondary .controls .button.time, .controls .controls_secondary .button.time, .controls_secondary .controls .button.money, .controls .controls_secondary .button.money,
  .controls_secondary .controls_primary .button.time, .controls_primary .controls_secondary .button.time,
  .controls_secondary .controls_primary .button.money, .controls_primary .controls_secondary .button.money, .controls_secondary .button:active {
    color: #4b5a6c;
    background: #7d8c9e;
    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2); }
  .controls_secondary .button:hover {
    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2); }

.controls_secondary .controls_secondary_tooltip {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  color: #cacfd5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  border: 1px solid #edeff1;
  background-color: transparent;
  height: 30px;
  width: auto;
  width: initial;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 0px;
  border-color: #7d8c9e;
  box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2), inset 0px 0px 4px rgba(99, 180, 215, 0.2);
  -webkit-transition: 300ms color ease, 300ms background-color ease, 300ms border-color ease;
  transition: 300ms color ease, 300ms background-color ease, 300ms border-color ease; }
  .controls_secondary .controls_secondary_tooltip.selected, .controls_secondary .controls_secondary_tooltip:active {
    color: #4b5a6c;
    background: #7d8c9e;
    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2); }
  .controls_secondary .controls_secondary_tooltip:hover {
    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2);
    background-color: #53829b; }

.priority_status {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #cacfd5;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 19px;
  width: 36px;
  border-radius: 20px;
  padding: 0 7px 0 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: #606d7c;
  opacity: 0.8;
  -webkit-transition: 300ms width ease;
  transition: 300ms width ease;
  cursor: default; }
  .priority_status svg:nth-child(2) {
    margin-left: 4px; }
  .priority_status svg:nth-child(3) {
    margin-left: 4px; }
  .priority_status.dummy {
    width: 0;
    padding: 0; }
  .priority_status.singleEl {
    padding: 0;
    width: 19px; }
  .priority_status.twoEl {
    width: 34px; }
  .priority_status.threeEl {
    width: 54px; }
  .priority_status.priority_status_current, .priority_status.priority_status_add {
    background: rgba(78, 108, 130, 0.5);
    width: auto;
    width: initial;
    height: 22px;
    padding: 0 10px; }
    .priority_status.priority_status_current svg, .priority_status.priority_status_add svg {
      margin-right: 7px; }
  .priority_status.priority_status_current .icon_closex {
    margin: 0 0 0 4px; }
    .priority_status.priority_status_current .icon_closex path {
      fill: #6b9fb9;
      -webkit-transition: 200ms fill ease;
      transition: 200ms fill ease; }
    .priority_status.priority_status_current .icon_closex:hover path {
      fill: #ffffff;
      -webkit-transition: 200ms fill ease;
      transition: 200ms fill ease; }
  .priority_status.priority_status_add {
    padding-right: 0; }
    .priority_status.priority_status_add button {
      border-radius: 22px;
      border-color: #ffffff;
      color: #fff;
      width: 22px;
      height: 22px;
      background: #5d6a7a;
      margin-left: 6px;
      line-height: 16px; }
      .priority_status.priority_status_add button:hover {
        background-color: #53829b;
        color: #edeff1; }
        .priority_status.priority_status_add button:hover span {
          color: #edeff1; }
      .priority_status.priority_status_add button:active, .priority_status.priority_status_add button.active {
        background-color: #55a8cb; }
        .priority_status.priority_status_add button:active.borderless, .priority_status.priority_status_add button.active.borderless {
          border-color: #55a8cb; }
        .priority_status.priority_status_add button:active span, .priority_status.priority_status_add button.active span {
          color: #edeff1; }

.datepicker_toggler_placeholder .datepicker_toggler {
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #edeff1;
  background: rgba(63, 75, 90, 0.5);
  border-radius: 3px;
  padding: 6px 8px 7px 11px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: 200ms background-color ease, 200ms color ease;
  transition: 200ms background-color ease, 200ms color ease; }
  .datepicker_toggler_placeholder .datepicker_toggler .text_mutted {
    color: #a7afb9;
    margin: 0 0px 0 4px;
    -webkit-transition: 200ms color ease;
    transition: 200ms color ease; }
  .datepicker_toggler_placeholder .datepicker_toggler .calendar_icon {
    margin-left: 7px;
    cursor: pointer; }
    .datepicker_toggler_placeholder .datepicker_toggler .calendar_icon .stroke.path1,
    .datepicker_toggler_placeholder .datepicker_toggler .calendar_icon .stroke.path2 {
      -webkit-transition: 200ms -webkit-transform ease;
      transition: 200ms -webkit-transform ease;
      transition: 200ms transform ease;
      transition: 200ms transform ease, 200ms -webkit-transform ease;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); }

.datepicker_toggler_placeholder:hover:not(.active) .datepicker_toggler {
  background: #3f4b5a; }
  .datepicker_toggler_placeholder:hover:not(.active) .datepicker_toggler .calendar_icon .background {
    fill: #58b3d8; }
  .datepicker_toggler_placeholder:hover:not(.active) .datepicker_toggler .calendar_icon .stroke {
    fill: #58b3d8; }

.datepicker_toggler_placeholder:hover:not(.active) .text_mutted {
  color: #ffffff; }

.datepicker_toggler_placeholder.active .datepicker_toggler {
  background: #55a8cb; }

.datepicker_toggler_placeholder.active .text_mutted {
  color: #ffffff; }

.datepicker_toggler_placeholder.active .calendar_icon .stroke {
  fill: #ffffff; }
  .datepicker_toggler_placeholder.active .calendar_icon .stroke.path1 {
    -webkit-transform: translateX(4px);
            transform: translateX(4px); }
  .datepicker_toggler_placeholder.active .calendar_icon .stroke.path2 {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px); }

.datepicker_toggler_placeholder.active .text_mutted {
  color: #ffffff; }

.datepicker_footer {
  padding: 0 15px;
  display: flex;
  flex: 1 0 auto; }
  .datepicker_footer .datepicker_footer_entire_label {
    flex: 0 0 115px; }
    .datepicker_footer .datepicker_footer_entire_label .entire_label {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #41acd7; }
  .datepicker_footer .datepicker_footer_pickers {
    flex: 1 0 auto; }
    .datepicker_footer .datepicker_footer_pickers ul {
      list-style: none;
      display: flex;
      flex-flow: column wrap;
      height: 58px; }
    .datepicker_footer .datepicker_footer_pickers li {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(100% / 4);
      text-align: left;
      padding-right: 39px; }
      .datepicker_footer .datepicker_footer_pickers li:nth-child(odd) {
        margin-bottom: 17px; }
      .datepicker_footer .datepicker_footer_pickers li span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #41acd7; }

.day_module,
.week_module {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 80px;
  min-height: 80px;
  padding: 9px 10px 10px;
  background: #3f4b5a;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: 200ms background-color ease;
  transition: 200ms background-color ease; }
  .day_module.day_module,
  .week_module.day_module {
    padding: 9px 8px 10px; }
  .day_module:hover,
  .week_module:hover {
    background: #333f4e; }
  .day_module:focus,
  .week_module:focus {
    outline: none; }
  .day_module:active, .day_module.active,
  .week_module:active,
  .week_module.active {
    background: #55a8cb; }
    .day_module:active .day_module_date,
    .day_module:active .day_module_content,
    .day_module:active .day_module_content_label, .day_module.active .day_module_date,
    .day_module.active .day_module_content,
    .day_module.active .day_module_content_label,
    .week_module:active .day_module_date,
    .week_module:active .day_module_content,
    .week_module:active .day_module_content_label,
    .week_module.active .day_module_date,
    .week_module.active .day_module_content,
    .week_module.active .day_module_content_label {
      color: #ffffff; }
  .day_module.dummy,
  .week_module.dummy {
    opacity: 0.7; }
  .day_module.disabled,
  .week_module.disabled {
    cursor: default;
    pointer-events: none; }
    .day_module.disabled .day_module_date,
    .week_module.disabled .day_module_date {
      color: #6a7888; }
    .day_module.disabled .day_module_content,
    .week_module.disabled .day_module_content {
      color: #7b8796; }
    .day_module.disabled .day_module_content_label,
    .week_module.disabled .day_module_content_label {
      color: #6a7888; }

.day_module_date {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #a7afb9;
  margin-bottom: 7px;
  -webkit-transition: 300ms color ease;
  transition: 300ms color ease; }

.day_module_content {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #cacfd5;
  -webkit-transition: 300ms color ease;
  transition: 300ms color ease; }

.day_module_content_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #a7afb9;
  -webkit-transition: 300ms color ease;
  transition: 300ms color ease; }

.week_module {
  width: 135px;
  align-items: flex-start;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  background-color: #4b5a6c;
  -webkit-transition: 300ms width ease;
  transition: 300ms width ease; }
  .week_module:hover {
    background: #394655; }
  .week_module:active:not(.disabled) {
    background: #55a8cb; }
  .week_module.disabled {
    cursor: default;
    pointer-events: none; }
  .week_module.report {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .week_module.report .week_module_date_placeholder {
      width: 121px; }
    .week_module.report:hover {
      background-color: #4b5a6c;
      cursor: default; }
    .week_module.report.dummy {
      opacity: 1; }
      .week_module.report.dummy .week_module_data_placeholder {
        background: #506073; }

.week_module_date_placeholder .week_module_date {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #a7afb9;
  display: block;
  margin-bottom: 8px; }

.week_module_date_placeholder .week_module_content {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #cacfd5; }

.week_module_date_placeholder .week_module_content_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #a7afb9; }

.week_module_data_placeholder {
  flex: 0 1 49%;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.week_module_task_display_block {
  border-radius: 3px;
  min-height: 56px;
  height: 100%;
  width: 0px;
  margin-right: 1px;
  -webkit-transition: 300ms width ease, 300ms background-color ease;
  transition: 300ms width ease, 300ms background-color ease; }
  .week_module_task_display_block:last-child {
    margin-right: 0; }

.report_task_tooltip {
  display: block; }

.week_module.task_settings {
  -webkit-transition: 200ms background-color ease;
  transition: 200ms background-color ease; }
  .week_module.task_settings:hover {
    cursor: pointer;
    background: #333f4e; }
  .week_module.task_settings.dummy {
    opacity: 1; }
    .week_module.task_settings.dummy .week_module_data_placeholder {
      background: #506073; }

.tippy-popper[x-placement^='top'] [x-arrow] {
  border-top-color: #3f4b5a; }

.or {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #4b5a6c;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #aeb4bc;
  margin-bottom: 8px; }

.layout {
  display: flex;
  flex-flow: row nowrap;
  background: #506073;
  min-width: 100vw;
  min-height: 100vh; }

.sidebar {
  background: rgba(0, 0, 0, 0.2);
  min-width: 90px;
  max-width: 90px;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 42px; }
  .sidebar button {
    margin-bottom: 37px; }

.body {
  min-width: calc(100% - 90px);
  display: flex;
  flex-flow: row nowrap; }

.main_workspace {
  padding: 0px 18px 36px 36px;
  flex: 1 1 auto; }

.main_workspace_header,
.main_controls_header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 112px; }

.time_total_display {
  display: flex;
  align-items: center;
  height: 42px;
  background: #4b5a6c;
  border-radius: 3px;
  padding: 0 22px; }

.time_total_display_text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: right;
  color: #ffffff; }
  .time_total_display_text span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    text-align: right;
    color: #a7afb9; }

.tools_panel_placeholder {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #4b5a6c;
  border-radius: 3px;
  margin-bottom: 37px; }
  .tools_panel_placeholder .left {
    display: flex;
    align-items: center; }
  .tools_panel_placeholder .right {
    display: flex;
    align-items: center; }

.days_view_placeholder {
  display: flex; }

.days_view_placeholder_col {
  flex-flow: column nowrap; }

.days_view_header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap; }

.days_view_weeks_placeholder {
  flex: 0 0 135px;
  margin-right: 10px;
  display: flex;
  flex-flow: column nowrap;
  -webkit-transition: 300ms flex-basis ease, 300ms margin ease;
  transition: 300ms flex-basis ease, 300ms margin ease; }
  .days_view_weeks_placeholder.report {
    flex-basis: 100%;
    margin-right: 0px; }

.days_view_weeks_label {
  margin-bottom: 4px;
  min-height: 21px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #788594;
  border-top: 1px solid #788594; }

.days_view_weeks_column {
  display: flex;
  flex-flow: column nowrap; }

.week_module {
  margin: 5px 0; }

.days_view_days_placeholder {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% - 145px);
  display: flex;
  flex-flow: column nowrap; }

.days_view_days_labels {
  margin-bottom: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none; }
  .days_view_days_labels li {
    flex: 0 1;
    flex-basis: calc((100% - 60px) / 7);
    height: 21px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #788594;
    border-top: 1px solid #788594; }

.days_view_days_rows {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.days_view_days_rows_daily {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.day_module {
  flex: 0 1;
  flex-basis: calc((100% - 135px - 60px) / 7);
  margin: 5px 0; }

.main_controls {
  width: 320px;
  max-width: 320px;
  padding: 0 33px 36px 18px;
  box-sizing: content-box; }

.tasks_panel_placeholder {
  background: #586a7e;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 4px; }

.tasks_panel_info {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px; }

.tasks_panel_selected_day,
.tasks_panel_selected_hours {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 5px);
  background: #4b5a6c;
  opacity: 0.5;
  border-radius: 3px;
  padding: 10px 13px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #edeff1; }
  .tasks_panel_selected_day .mutted,
  .tasks_panel_selected_hours .mutted {
    color: #a7afb9; }

.tasks_panel_selected_day {
  margin-right: 10px; }

.tasks_panel_selected_hours {
  justify-content: flex-start; }

.task_settings_item {
  padding: 4px 16px;
  margin-bottom: 4px;
  background: #3f4b5a;
  border-radius: 3px;
  min-height: 61px;
  -webkit-transition-property: background-color, padding-bottom, height;
  transition-property: background-color, padding-bottom, height;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .task_settings_item_with_error {
    padding-bottom: 16px;
    height: 68px !important; }
  .task_settings_item:last-child {
    margin-bottom: 0; }
  .task_settings_item .datepicker_toggler span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #edeff1; }
  .task_settings_item .datepicker_toggler .text_mutted {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #a7afb9; }
  .task_settings_item .task_settings_item_left .priority_status {
    margin-right: 12px; }
  .task_settings_item .task_settings_item_left_label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #a7afb9; }
  .task_settings_item .task_settings_item_right {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    max-width: 200px;
    min-width: 22px;
    overflow: hidden; }
  .task_settings_item .task_settings_item_error {
    position: absolute;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #dc4e41;
    bottom: 2px;
    opacity: 0;
    -webkit-transition: 200ms opacity ease;
    transition: 200ms opacity ease; }
    .task_settings_item .task_settings_item_error_shown {
      opacity: 1; }

.task_details {
  padding: 4px;
  margin-bottom: 3px;
  background: #3f4b5a;
  border-radius: 3px;
  -webkit-transition: 200ms background-color ease;
  transition: 200ms background-color ease;
  cursor: pointer; }
  .task_details:hover {
    background: #333f4e; }
    .task_details:hover .task_details_placeholder:after {
      border-top-color: #58b3d8; }

.report_tasks_panel_avg_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #a7afb9;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 5px);
  min-height: 100%;
  padding-left: 11px;
  margin-right: 10px; }

.task_settings_item {
  width: 100%;
  height: 61px;
  min-height: 61px; }

.task_details_placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 10px;
  height: 44px;
  position: relative; }
  .task_details_placeholder:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-left: 7px solid transparent;
    border-top: 7px solid #748290;
    -webkit-transition: 200ms border-top-color ease;
    transition: 200ms border-top-color ease; }

.task_details_left {
  display: flex;
  align-items: center;
  max-width: 70%; }
  .task_details_left svg {
    width: 34px;
    margin-right: 6px; }

.task_deatils_right {
  display: flex; }
  .task_deatils_right .priority_status {
    margin-right: 18px;
    margin-left: 12px; }
  .task_deatils_right .muted {
    color: #84909d; }

.task_details_left_text_container {
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .task_details_left_text_container .task_details_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .task_details_left_text_container span {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: #CDD1D5;
    text-align: left; }

.task_deatails_statusbar {
  width: 0;
  height: 3px;
  border-radius: 3px;
  -webkit-transition: 200ms width ease;
  transition: 200ms width ease; }

.auth {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  justify-content: center; }

.auth_errors {
  margin: 0 0 14px;
  min-height: 1em;
  text-align: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #f44336; }

.auth_placeholder {
  display: flex;
  flex-flow: column nowrap;
  align-items: center; }

.auth_logo {
  margin-bottom: 70px; }

.auth_intro {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 40px; }

.auth_social_buttons {
  margin-bottom: 49px; }

.auth_input {
  padding-top: 10px; }
  .auth_input:hover .MuiInputLabel-animated {
    color: #53829b; }
  .auth_input .MuiInputLabel-animated {
    color: #aeb4bc; }
  .auth_input .MuiInputLabel-animated.Mui-focused {
    color: #55a8cb; }
  .auth_input .MuiFormLabel-root.Mui-disabled {
    color: #aeb4bc; }
  .auth_input .MuiInput-formControl:before, .auth_input .MuiInput-formControl:after {
    display: none; }
  .auth_input input {
    border: none;
    border-bottom: 1px solid #aeb4bc;
    outline: none;
    background: transparent;
    min-width: 300px;
    height: 36px;
    padding: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    caret-color: #65cefb;
    -webkit-transition: 200ms border-color ease;
    transition: 200ms border-color ease; }
    .auth_input input.Mui-disabled:hover {
      border-color: #aeb4bc; }
    .auth_input input:hover {
      border-bottom: 1px solid #eaeef0; }
    .auth_input input:hover:not(:focus) ~ label {
      color: #53829b !important; }
    .auth_input input:focus {
      border-color: #55a8cb; }

.file_input {
  padding-top: 10px; }
  .file_input:hover .MuiInputLabel-animated {
    color: #53829b; }
  .file_input .MuiInputLabel-animated {
    color: #aeb4bc; }
  .file_input .MuiInputLabel-animated.Mui-focused {
    color: #55a8cb; }
  .file_input .MuiFormLabel-root.Mui-disabled {
    color: #aeb4bc; }
  .file_input .MuiInput-formControl:before, .file_input .MuiInput-formControl:after {
    display: none; }
  .file_input label {
    border: none;
    border-bottom: 1px solid #aeb4bc;
    outline: none;
    background: transparent;
    min-width: 300px;
    height: 36px;
    padding: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    caret-color: #65cefb;
    -webkit-transition: 200ms border-color ease;
    transition: 200ms border-color ease; }
    .file_input label.Mui-disabled:hover {
      border-color: #aeb4bc; }
    .file_input label:hover {
      border-bottom: 1px solid #eaeef0; }
    .file_input label:hover:not(:focus) ~ label {
      color: #53829b !important; }
    .file_input label:focus {
      border-color: #55a8cb; }
  .file_input .file_input_customized {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .file_input .file_input_customized .file_name {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff; }
    .file_input .file_input_customized .file_choose {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #eaeef0; }

.update_success {
  display: flex;
  flex-flow: column nowrap;
  align-items: center; }
  .update_success .update_success_img {
    margin-bottom: 19px; }
  .update_success .update_success_text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 40px; }

.colorpicker {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: 3px;
  width: 237px;
  margin: -8px;
  padding: 8px;
  background: #ffffff; }
  .colorpicker li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: relative;
    margin: 11px;
    -webkit-transition: 200ms border-color ease;
    transition: 200ms border-color ease; }
    .colorpicker li:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(1.2);
              transform: translate(-50%, -50%) scale(1.2);
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px solid transparent;
      -webkit-transition: 200ms border-color ease, 200ms -webkit-transform ease;
      transition: 200ms border-color ease, 200ms -webkit-transform ease;
      transition: 200ms border-color ease, 200ms transform ease;
      transition: 200ms border-color ease, 200ms transform ease, 200ms -webkit-transform ease; }
    .colorpicker li.active:after {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
      border: 1px solid #55a8cb; }
    .colorpicker li:hover:after {
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
      border: 1px solid rgba(85, 168, 203, 0.4); }

.edit_current_color {
  width: 22px;
  height: 22px;
  border-radius: 50%; }

.iconpicker {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  border-radius: 3px;
  width: 250px;
  margin: -8px;
  padding: 20px;
  background: #ffffff; }
  .iconpicker li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    -webkit-transition: 200ms border-color ease;
    transition: 200ms border-color ease; }
    .iconpicker li svg path {
      -webkit-transition: 200ms fill ease;
      transition: 200ms fill ease; }
    .iconpicker li:hover {
      border: 1px solid #55a8cb; }
      .iconpicker li:hover svg path {
        fill: #506073; }

.tippy-popper[x-placement^='left'] .currencyes-theme [x-arrow] {
  border-left-color: #4b5a6c; }

.tippy-popper {
  max-width: 600px; }

.currencyes-theme .tippy-tooltip-content {
  margin: -8px; }

.currencyes-theme ul {
  list-style-type: none;
  padding: 11px 4px 11px 4px; }
  .currencyes-theme ul li {
    cursor: pointer;
    text-align: left;
    padding: 0px 8px;
    background-color: transparent;
    -webkit-transition: 200ms background-color ease;
    transition: 200ms background-color ease; }
    .currencyes-theme ul li .symbol {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #edeff1;
      -webkit-transition: 200ms color ease;
      transition: 200ms color ease; }
    .currencyes-theme ul li .name {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #a7afb9;
      -webkit-transition: 200ms color ease;
      transition: 200ms color ease; }
    .currencyes-theme ul li:hover {
      background-color: rgba(63, 75, 90, 0.2); }
      .currencyes-theme ul li:hover .name {
        color: #448aab; }
      .currencyes-theme ul li:hover .symbol {
        color: #41acd7; }

.total_money-theme {
  border-radius: 3px;
  padding: 10px 15px;
  background: #3f4b5a; }
  .total_money-theme .enter {
    background: #3f4b5a; }
  .total_money-theme .arrow-regular[x-arrow] {
    border-bottom: 7px solid #3f4b5a; }

.main_datepicker-theme {
  background-color: #455263;
  padding: 0; }
  .main_datepicker-theme .arrow-regular[x-arrow] {
    border-bottom: 7px solid #455263; }

.tag-filter-theme {
  box-shadow: 0px 0px 4px rgba(59, 70, 84, 0.3);
  background-color: #5E697E;
  padding: 5px; }

[x-placement='bottom'] .tag-filter-theme .arrow-regular[x-arrow] {
  border-bottom: 7px solid #5E697E; }

.truncate-tooltip-theme {
  box-shadow: 0px 0px 4px rgba(59, 70, 84, 0.3);
  background-color: #697b8e; }

[x-placement='bottom'] .truncate-tooltip-filter-theme .arrow-regular[x-arrow],
[x-placement='bottom'] .truncate-tooltip-theme .arrow-regular[x-arrow] {
  border-bottom: 7px solid #697b8e; }

[x-placement='top'] .truncate-tooltip-filter-theme .arrow-regular[x-arrow],
[x-placement='top'] .truncate-tooltip-theme .arrow-regular[x-arrow] {
  border-top: 7px solid #697b8e; }

.tasks-filter-theme {
  background-color: #586a7e; }

.tasks_filter_list {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #586a7e; }

.tasks_filter_list_all .task_details_placeholder:after {
  display: none; }

.filter_tag_item_placeholder {
  background: #3F4B5A;
  border-radius: 3px;
  width: 85px;
  height: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .filter_tag_item_placeholder > span {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #CACFD5; }
  .filter_tag_item_placeholder:not(:last-child) {
    margin-bottom: 2px; }

.edit_current_currency .symbol {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #edeff1; }

.edit_current_currency .name {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #a7afb9; }

.check_dummy {
  width: 22px;
  height: 22px;
  border: 2px solid #545b65;
  border-radius: 50%;
  cursor: pointer; }

.track-vertical {
  background-color: rgba(255, 255, 255, 0.1);
  width: 3px; }

.thumb-vertical {
  background-color: rgba(255, 255, 255, 0.2); }

.no_data_placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 460px; }

.no_data_placeholder_img {
  margin-bottom: 50px; }

.no_data_placeholder_title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 26px; }

.no_data_placeholder_text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #cacfd5; }

.integrations {
  background: #374251;
  border-radius: 3px;
  width: 100%;
  max-width: 542px;
  min-height: 324px;
  padding: 34px 19px; }
  .integrations-title {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #fff; }
  .integrations-text {
    font-size: 15px;
    line-height: 18px;
    color: #A7AFB9;
    max-width: 350px; }
  .integrations-copyLinkBut {
    width: 160px;
    height: 40px;
    margin: 16px 0 22px;
    font-size: 0px;
    text-align: center;
    color: #EAEEF0;
    border: 1px solid #EDEFF1;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    -webkit-transition: background 0.4s ease;
    transition: background 0.4s ease; }
    .integrations-copyLinkBut span {
      font-family: 'Lato', arial, sans-serif;
      font-weight: 400;
      display: inline-block;
      font-size: 16px; }
    .integrations-copyLinkBut > span {
      position: relative;
      top: -2px; }
    .integrations-copyLinkBut:hover {
      background: #53829B; }
    .integrations-copyLinkBut.-active {
      background: #55A8CB; }
    .integrations-copyLinkBut:focus {
      outline: none; }
  .integrations-checkIcon {
    display: inline-block;
    margin-right: 3px; }
  .integrations-linksBox {
    margin-top: 17px;
    font-size: 0; }
  .integrations-link {
    display: inline-block;
    align-items: center; }
    .integrations-link::after {
      display: none; }
    .integrations-link:not(:last-child) {
      margin-right: 36px; }

.modal-overlay {
  position: fixed;
  inset: 0;
  background: #000000aa;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  display: flex;
  padding: 30px; }

.modal {
  margin: auto;
  width: 100%;
  max-width: 460px;
  background: #506073;
  border-radius: 10px;
  padding: 30px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  outline: none; }
  .modal .modal-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center; }
  .modal .modal-text {
    text-align: center; }
    .modal .modal-text p {
      margin: 10px 0 0; }
  .modal .error {
    font-size: 12px;
    margin-top: 10px;
    color: #e7483d; }
  .modal .modal-actions {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 30px; }
    .modal .modal-actions > button {
      display: block;
      width: 100%;
      cursor: pointer;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      color: #fff;
      padding: 13px;
      border-radius: 5px;
      border: 0;
      box-shadow: inset 0 0 1px 1px #fff;
      outline: none;
      background: transparent;
      -webkit-transition: all 200ms;
      transition: all 200ms; }
      .modal .modal-actions > button.del-btn {
        background: #e7483d;
        box-shadow: none; }
        .modal .modal-actions > button.del-btn:hover {
          background: #cd2014; }
      .modal .modal-actions > button:hover {
        background: #53829b; }
