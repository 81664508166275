@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Light.woff2') format('woff2'),
    url('./assets/fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Regular.woff2') format('woff2'),
    url('./assets/fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Bold.woff2') format('woff2'),
    url('./assets/fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tippy-tooltip {
  border-radius: 3px;
  background-color: #4b5a6c;
  padding: 8px;
  .tippy-tooltip-content {
    .button {
      &:hover {
        box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2);
      }
    }
  }
}

//.tippy-popper[x-placement^=top] [x-arrow] {border-top-color: #4B5A6C;}
.tippy-popper[x-placement^='bottom'] [x-arrow] {
  border-bottom-color: #4b5a6c;
}
.tippy-popper[x-placement^='left'] [x-arrow] {
  border-left-color: #ffffff;
}
.tippy-popper[x-placement^='tirght'] [x-arrow] {
  border-right-color: #4b5a6c;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex-v {
  flex-direction: column;
}

// ---------- //
// ui variables
// ---------- //

$transition_speed: 300ms;
$transition_speed_fast: 200ms;
// ui colors

//bg
$bg_primary: #506073;
$bg_secondary: #3b4654;

// buttons
$btn_color_disabled: #84909d;
$btn_color_active: #55a8cb;
$btn_color_hovered: #53829b;

// text
$text_1: #edeff1;
$text_2: #cacfd5;
$text_3: #a7afb9;
$text_4: #84909d;
$text_5: #657384;

//links
$link_color: #41acd7;
$link_color_hovered: #58b3d8;
$link_color_visited: #7b93d1;

// ---------- //
// Typography //
// ---------- //

h1,
h2,
h3 {
  font-family: Lato;
  font-style: normal;
  color: #ffffff;
}
h1 {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
}
h2 {
  font-weight: normal;
  font-size: 27px;
  line-height: 32px;
  color: #edeff1;
}
h3 {
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #cacfd5;
}
p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #cacfd5;
  &.text_big {
    font-size: 18px;
    line-height: 22px;
  }
}
small {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #a7afb9;
}
a,
.link {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;

  font-size: 13px;
  line-height: 16px;

  color: $link_color;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  transition: $transition_speed_fast color ease;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0px;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    background-color: $link_color;
    transition: $transition_speed_fast height ease;
  }

  &:visited {
    text-decoration: underline;
    color: $link_color_visited;
  }
  &:hover {
    color: $link_color_hovered;
    &:after {
      height: 50%;
      background-color: $link_color-hovered;
    }
  }
  &:active {
    color: $link_color_hovered;
    &:after {
      height: 100%;
      background-color: $link_color-hovered;
    }
  }
}
h3 a {
  font-size: 22px;
  line-height: 26px;
}
p a {
  font-size: 15px;
  line-height: 18px;
}
.label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #abb3bc;

  background: rgba(88, 179, 216, 0.2);
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  .icon_closex {
    path {
      fill: #6b9fb9;
      transition: $transition_speed fill ease;
    }
  }
  &:hover {
    .icon_closex {
      path {
        fill: #ffffff;
      }
    }
  }
}
.preloader {
  animation-name: preloader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// ---------- //
// ui elements //
// ---------- //

// buttons
.button_socials {
  position: relative;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 224px;
  height: 44px;
  border: none;
  border-radius: 6px;
  transition: $transition_speed_fast opacity ease;
  &:hover {
    opacity: 0.8;
  }
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #edeff1;
  border-radius: 3px;
  width: 160px;
  height: 40px;
  padding: 0 0 1px;

  background-color: transparent;
  color: #eaeef0;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  cursor: pointer;

  transition: $transition_speed color ease,
    $transition_speed background-color ease, $transition_speed border-color ease;

  &:hover {
    background-color: $btn_color_hovered;
    color: #edeff1;
    span {
      color: $text_1;
    }
  }
  &:focus {
    outline: none;
  }
  &:active,
  &.active {
    background-color: $btn_color_active;
    &.borderless {
      border-color: $btn_color_active;
    }
    span {
      color: $text_1;
    }
  }
  &[disabled] {
    color: $btn_color_disabled;
    border-color: $btn_color_disabled;
    cursor: default;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $btn_color_disabled;
      border-color: $btn_color_disabled;
      background-color: transparent;
    }
  }
  span {
    color: $text_3;
    transition: $transition_speed color ease;
  }
  &.google {
    @extend .button_socials;
    background-color: #dc4e41;
    &:disabled {
      background: rgba(220, 78, 65, 0.6);
    }
  }
  &.facebook {
    @extend .button_socials;
    background: #4b6fbc;
    &:disabled {
      background: rgba(220, 78, 65, 0.6);
    }
  }
  &.apple {
    @extend .button_socials;
    background: #1d1d1d;
    &:disabled {
      background: rgba(220, 78, 65, 0.6);
    }
  }
}
.button_nav {
  border: none;
  background: none;
  position: relative;
  cursor: pointer;

  svg {
    path {
      transition: $transition_speed_fast all ease;
      fill: #fff;
    }

    &:active path,
    &.active path {
      fill: #58b3d8;
      
      &.background {
        fill-opacity: 0.2;
      }
    }

    &.delete path {
      fill-opacity: 0;
    }
  }

  &:hover {
    svg path {
      fill: #58b3d8;

      .background {
        fill-opacity: 0;
      }
    }

    .delete path {
      stroke: #cd2014;
    }
  }
  &:focus {
    outline: none;
  }
}
button:disabled .check_icon {
  background-color: rgb(75, 90, 108);
}
.check_icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 22px;
  background: #55a8cb;
  padding: 7px 5px 6px;
}
.button_next,
.button_prev,
.button_edit {
  min-width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  //background: rgba(88, 179, 216, 0);
  border: none;
  border-radius: 22px;
  transition: $transition_speed_fast background-color ease;
  &:hover {
    opacity: 1;
    background: rgba(88, 179, 216, 0.3);
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  &:focus {
    outline: none;
  }
  &.active,
  &:active {
    opacity: 1;
    background: #55a8cb;
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  svg {
    path {
      transition: $transition_speed_fast fill ease;
    }
  }
}
// tooltip button
.active {
  .button {
    @extend .button.active;
  }
}
.button_check {
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  //background: rgba(88, 179, 216, 0);
  border: none;
  border-radius: 22px;
  transition: $transition_speed_fast background-color ease;
  &:hover {
    opacity: 1;
    background: rgba(88, 179, 216, 0.3);
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  &:focus {
    outline: none;
  }
  &.active,
  &:active {
    opacity: 1;
    background: #55a8cb;
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  * {
    position: absolute;
  }
}
// controls
.controls,
.controls_primary {
  display: flex;
  flex-flow: row nowrap;
  .button {
    padding: 7px 12px;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    border-radius: 0;
    border-left-width: 0px;
    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-left-width: 1px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &.time,
    &.money {
      @extend .button.active;
    }
  }
  &.disabled button {
    border-color: #84909d;
    &:not(.active) {
      color: #84909d;
      &:hover,
      &:active {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.controls_secondary {
  .button {
    border-color: #7d8c9e;
    color: $text_2;
    width: initial;
    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2),
      inset 0px 0px 4px rgba(99, 180, 215, 0.2);
    &.active,
    &:active {
      color: #4b5a6c;
      background: #7d8c9e;
      box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2);
    }
    &:hover {
      box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2);
    }
  }
  .controls_secondary_tooltip {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    text-align: center;
    color: $text_2;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 12px;
    border: 1px solid #edeff1;
    background-color: transparent;
    height: 30px;
    width: initial;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-width: 0px;
    border-color: #7d8c9e;

    box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2),
      inset 0px 0px 4px rgba(99, 180, 215, 0.2);
    transition: 300ms color ease, 300ms background-color ease, 300ms border-color ease;
    &.selected,
    &:active {
      color: #4b5a6c;
      background: #7d8c9e;
      box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2);
    }
    &:hover {
      box-shadow: 0px 0px 4px rgba(99, 180, 215, 0.2);
      background-color: #53829b;
    }
  }
}
// task statuses
.priority_status {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #cacfd5;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 19px;
  width: 36px;
  border-radius: 20px;
  padding: 0 7px 0 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: #606d7c;
  opacity: 0.8;
  transition: $transition_speed width ease;
  cursor: default;
  svg:nth-child(2) {
    margin-left: 4px;
  }
  svg:nth-child(3) {
    margin-left: 4px;
  }
  &.dummy {
    width: 0;
    padding: 0;
  }
  &.singleEl {
    padding: 0;
    width: 19px;
  }
  &.twoEl {
    width: 34px;
  }
  &.threeEl {
    width: 54px;
  }

  &.priority_status_current,
  &.priority_status_add {
    // background: #4E6C82;
    // opacity: 0.5;
    background: rgba(78, 108, 130, 0.5);
    width: initial;
    height: 22px;
    padding: 0 10px;
    svg {
      margin-right: 7px;
    }
  }
  &.priority_status_current {
    .icon_closex {
      margin: 0 0 0 4px;
      path {
        fill: #6b9fb9;
        transition: $transition_speed_fast fill ease;
      }
      &:hover {
        path {
          fill: #ffffff;
          transition: $transition_speed_fast fill ease;
        }
      }
    }
  }
  &.priority_status_add {
    padding-right: 0;
    button {
      border-radius: 22px;
      border-color: #ffffff;
      color: #fff;
      width: 22px;
      height: 22px;
      background: #5d6a7a;
      margin-left: 6px;
      line-height: 16px;
      &:hover {
        background-color: $btn_color_hovered;
        color: #edeff1;
        span {
          color: $text_1;
        }
      }
      &:active,
      &.active {
        background-color: $btn_color_active;
        &.borderless {
          border-color: $btn_color_active;
        }
        span {
          color: $text_1;
        }
      }
    }
  }
}

// datepicker
.datepicker_toggler_placeholder {
  .datepicker_toggler {
    display: flex;
    align-items: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #edeff1;
    background: rgba(63, 75, 90, 0.5);
    border-radius: 3px;
    padding: 6px 8px 7px 11px;
    cursor: pointer;
    user-select: none;
    transition: $transition_speed_fast background-color ease,
      $transition_speed_fast color ease;
    .text_mutted {
      color: #a7afb9;
      margin: 0 0px 0 4px;
      transition: $transition_speed_fast color ease;
    }
    .calendar_icon {
      margin-left: 7px;
      cursor: pointer;
      .stroke.path1,
      .stroke.path2 {
        transition: $transition_speed_fast transform ease;
        transform: translateX(0px);
      }
    }
  }
  &:hover:not(.active) {
    .datepicker_toggler {
      background: #3f4b5a;
      .calendar_icon {
        .background {
          fill: #58b3d8;
        }
        .stroke {
          fill: #58b3d8;
        }
      }
    }
    .text_mutted {
      color: #ffffff;
    }
  }
  &.active {
    .datepicker_toggler {
      background: #55a8cb;
    }
    .text_mutted {
      color: #ffffff;
    }
    .calendar_icon {
      .stroke {
        fill: #ffffff;
        &.path1 {
          transform: translateX(4px);
        }
        &.path2 {
          transform: translateX(-4px);
        }
      }
    }
    .text_mutted {
      color: #ffffff;
    }
  }
}
.datepicker_footer {
  padding: 0 15px;
  display: flex;
  flex: 1 0 auto;
  .datepicker_footer_entire_label {
    flex: 0 0 115px;
    .entire_label {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #41acd7;
    }
  }
  .datepicker_footer_pickers {
    flex: 1 0 auto;
    ul {
      list-style: none;
      display: flex;
      flex-flow: column wrap;
      height: 58px;
    }
    li {
      flex: 0 1 calc(100% / 4);
      text-align: left;
      padding-right: 39px;
      &:nth-child(odd) {
        margin-bottom: 17px;
      }
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #41acd7;
      }
    }
  }
}
// day module
.day_module,
.week_module {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 80px;
  min-height: 80px;
  padding: 9px 10px 10px;
  background: #3f4b5a;
  border-radius: 3px;
  cursor: pointer;
  transition: $transition_speed_fast background-color ease;
  &.day_module {
    padding: 9px 8px 10px;
  }
  &:hover {
    background: #333f4e;
  }
  &:focus {
    outline: none;
  }
  &:active,
  &.active {
    background: #55a8cb;
    .day_module_date,
    .day_module_content,
    .day_module_content_label {
      color: #ffffff;
    }
  }
  &.dummy {
    opacity: 0.7;
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    .day_module_date {
      color: #6a7888;
    }
    .day_module_content {
      color: #7b8796;
    }
    .day_module_content_label {
      color: #6a7888;
    }
  }
}
.day_module_date {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #a7afb9;
  margin-bottom: 7px;
  transition: $transition_speed color ease;
}
.day_module_content {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #cacfd5;
  transition: $transition_speed color ease;
}
.day_module_content_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #a7afb9;
  transition: $transition_speed color ease;
}
.week_module {
  width: 135px;
  align-items: flex-start;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  background-color: #4b5a6c;
  transition: $transition_speed width ease;
  &:hover {
    background: #394655;
  }
  &:active:not(.disabled) {
    background: #55a8cb;
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
  &.report {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .week_module_date_placeholder {
      width: 121px;
    }
    &:hover {
      background-color: #4b5a6c;
      cursor: default;
    }
    &.dummy {
      opacity: 1;
      .week_module_data_placeholder {
        background: #506073;
      }
    }
  }
}
.week_module_date_placeholder {
  .week_module_date {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #a7afb9;
    display: block;
    margin-bottom: 8px;
  }
  .week_module_content {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #cacfd5;
  }
  .week_module_content_label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #a7afb9;
  }
}
.week_module_data_placeholder {
  flex: 0 1 49%;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.week_module_task_display_block {
  border-radius: 3px;
  min-height: 56px;
  height: 100%;
  width: 0px;
  margin-right: 1px;
  transition: $transition_speed width ease,
    $transition_speed background-color ease;
  &:last-child {
    margin-right: 0;
  }
}
.report_task_tooltip {
  display: block;
}
.week_module {
  &.report {
    .week_module_data_placeholder {
    }
  }
  &.task_settings {
    transition: $transition_speed_fast background-color ease;
    &:hover {
      cursor: pointer;
      background: #333f4e;
    }
    &.dummy {
      opacity: 1;
      .week_module_data_placeholder {
        background: #506073;
      }
    }
  }
}
.tippy-popper[x-placement^='top'] [x-arrow] {
  border-top-color: #3f4b5a;
}

// ---------- //
// icons //
// ---------- //

.or {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #4b5a6c;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #aeb4bc;
  margin-bottom: 8px;
}

.layout {
  display: flex;
  flex-flow: row nowrap;
  background: #506073;
  min-width: 100vw;
  min-height: 100vh;
}
.sidebar {
  background: rgba(0, 0, 0, 0.2);
  min-width: 90px;
  max-width: 90px;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 42px;
  button {
    margin-bottom: 37px;
  }
}
.body {
  min-width: calc(100% - 90px);
  display: flex;
  flex-flow: row nowrap;
}
.main_workspace {
  padding: 0px 18px 36px 36px;
  flex: 1 1 auto;
}
.main_workspace_header,
.main_controls_header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 112px;
}
.time_total_display {
  display: flex;
  align-items: center;
  //width: 200px;
  height: 42px;
  background: #4b5a6c;
  border-radius: 3px;
  padding: 0 22px;
}
.time_total_display_text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: right;
  color: #ffffff;
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    text-align: right;
    color: #a7afb9;
  }
}
.tools_panel_placeholder {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #4b5a6c;
  border-radius: 3px;
  margin-bottom: 37px;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
}
.days_view_placeholder {
  display: flex;
}

.days_view_placeholder_col {
  flex-flow: column nowrap;
}
.days_view_header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.days_view_weeks_placeholder {
  flex: 0 0 135px;
  margin-right: 10px;
  display: flex;
  flex-flow: column nowrap;
  transition: $transition_speed flex-basis ease, $transition_speed margin ease;
  &.report {
    flex-basis: 100%;
    margin-right: 0px;
  }
}
.days_view_weeks_label {
  margin-bottom: 4px;
  min-height: 21px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #788594;
  border-top: 1px solid #788594;
}
.days_view_weeks_column {
  display: flex;
  flex-flow: column nowrap;
}
.week_module {
  margin: 5px 0;
}
.days_view_days_placeholder {
  flex: 0 1 calc(100% - 145px);
  display: flex;
  flex-flow: column nowrap;
}
.days_view_days_labels {
  margin-bottom: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  li {
    flex: 0 1;
    flex-basis: calc((100% - 60px) / 7);
    height: 21px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #788594;
    border-top: 1px solid #788594;
  }
}
.days_view_day {
}
.days_view_days_rows {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.days_view_days_rows_daily {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.day_module {
  flex: 0 1;
  flex-basis: calc((100% - 135px - 60px) / 7);
  margin: 5px 0;
}

.main_controls {
  width: 320px;
  max-width: 320px;
  padding: 0 33px 36px 18px;
  box-sizing: content-box;
}
.tasks_panel_placeholder {
  background: #586a7e;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 4px;
}
.tasks_panel_info {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}
.tasks_panel_selected_day,
.tasks_panel_selected_hours {
  flex: 0 1 calc(50% - 5px);
  //height: 42px;
  background: #4b5a6c;
  opacity: 0.5;
  border-radius: 3px;
  padding: 10px 13px;
  min-height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #edeff1;
  .mutted {
    color: #a7afb9;
  }
}
.tasks_panel_selected_day {
  margin-right: 10px;
}
.tasks_panel_selected_hours {
  justify-content: flex-start;
}

.task_settings_item {
  padding: 4px 16px;
  margin-bottom: 4px;
  background: #3f4b5a;
  border-radius: 3px;
  min-height: 61px;
  transition-property: background-color, padding-bottom, height;
  transition-duration: $transition_speed_fast;
  transition-timing-function: ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &_with_error {
    padding-bottom: 16px;
    height: 68px !important;
  }

  //cursor: pointer;
  // &:hover {
  //     background: #333F4E;
  //     .task_details_placeholder:after {
  //         border-top-color: #58B3D8;
  //     }
  // }
  &:last-child {
    margin-bottom: 0;
  }
  .datepicker_toggler {
    span {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      color: #edeff1;
    }
    .text_mutted {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      color: #a7afb9;
    }
  }
  .task_settings_item_left {
    .priority_status {
      margin-right: 12px;
    }
  }
  .task_settings_item_left_label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #a7afb9;
  }
  .task_settings_item_right {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    max-width: 200px;
    min-width: 22px;
    overflow: hidden;
  }

  .task_settings_item_error {
    position: absolute;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #dc4e41;
    bottom: 2px;
    opacity: 0;

    transition: $transition_speed_fast opacity ease;

    &_shown {
      opacity: 1;
    }
  }
}

.task_details {
  padding: 4px;
  margin-bottom: 3px;
  background: #3f4b5a;
  border-radius: 3px;
  transition: $transition_speed_fast background-color ease;

  cursor: pointer;
  &:hover {
    background: #333f4e;
    .task_details_placeholder:after {
      border-top-color: #58b3d8;
    }
  }
}
.report_tasks_panel_avg_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #a7afb9;

  display: flex;
  align-items: center;
  flex: 0 1 calc(50% - 5px);
  min-height: 100%; //height: 42px;
  padding-left: 11px;
  margin-right: 10px;
}

.task_settings_item {
  width: 100%;
  height: 61px;
  min-height: 61px;
}

.task_details_placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 10px;
  height: 44px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-left: 7px solid transparent;
    border-top: 7px solid #748290;
    transition: $transition_speed_fast border-top-color ease;
  }
}
.task_details_left {
  display: flex;
  align-items: center;
  max-width: 70%;
  svg {
    width: 34px;
    margin-right: 6px;
  }
}
.task_deatils_right {
  display: flex;
  .priority_status {
    margin-right: 18px;
    margin-left: 12px;
  }
  .muted {
    color: #84909d;
  }
}
.task_details_left_text_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .task_details_title {
    //text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: #CDD1D5;
    text-align: left;
  }
}

.task_deatails_statusbar {
  width: 0;
  height: 3px;
  border-radius: 3px;
  //background-color: #41ACD7;
  transition: $transition_speed_fast width ease;
}
.auth {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  // align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  justify-content: center;
}
.auth_errors {
  margin: 0 0 14px;
  min-height: 1em;
  text-align: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #f44336;
}
.auth_placeholder {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.logo {
}
.auth_logo {
  margin-bottom: 70px;
}
.auth_intro {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 40px;
}
.auth_social_buttons {
  margin-bottom: 49px;
}
.auth_input {
  padding-top: 10px;
  &:hover {
    .MuiInputLabel-animated {
      color: #53829b;
    }
  }
  .MuiInputLabel-animated {
    color: rgb(174, 180, 188);
  }
  .MuiInputLabel-animated.Mui-focused {
    color: rgb(85, 168, 203);
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #aeb4bc;
  }
  .MuiInput-formControl {
    &:before,
    &:after {
      display: none;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #aeb4bc;
    outline: none;
    background: transparent;
    min-width: 300px;
    height: 36px;
    padding: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    caret-color: #65cefb;
    transition: $transition_speed_fast border-color ease;
    &.Mui-disabled:hover {
      border-color: #aeb4bc;
    }
    &:hover {
      border-bottom: 1px solid #eaeef0;
    }
    &:hover:not(:focus) ~ label {
      color: #53829b !important;
    }
    &:focus {
      border-color: #55a8cb;
    }
  }
}

.file_input {
  padding-top: 10px;
  &:hover {
    .MuiInputLabel-animated {
      color: #53829b;
    }
  }
  .MuiInputLabel-animated {
    color: rgb(174, 180, 188);
  }
  .MuiInputLabel-animated.Mui-focused {
    color: rgb(85, 168, 203);
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #aeb4bc;
  }
  .MuiInput-formControl {
    &:before,
    &:after {
      display: none;
    }
  }
  label {
    border: none;
    border-bottom: 1px solid #aeb4bc;
    outline: none;
    background: transparent;
    min-width: 300px;
    height: 36px;
    padding: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    caret-color: #65cefb;
    transition: $transition_speed_fast border-color ease;
    &.Mui-disabled:hover {
      border-color: #aeb4bc;
    }
    &:hover {
      border-bottom: 1px solid #eaeef0;
    }
    &:hover:not(:focus) ~ label {
      color: #53829b !important;
    }
    &:focus {
      border-color: #55a8cb;
    }
  }
  .file_input_customized {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .file_name {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
    .file_choose {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #eaeef0;
    }
  }
}
.update_success {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .update_success_img {
    margin-bottom: 19px;
  }
  .update_success_text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 40px;
  }
}

.colorpicker {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: 3px;
  width: 237px;
  margin: -8px;
  padding: 8px;
  background: #ffffff;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: relative;
    margin: 11px;
    transition: $transition_speed_fast border-color ease;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.2);
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px solid transparent;
      transition: $transition_speed_fast border-color ease,
        $transition_speed_fast transform ease;
    }
    &.active:after {
      transform: translate(-50%, -50%) scale(1);
      border: 1px solid #55a8cb;
    }
    &:hover {
      &:after {
        transform: translate(-50%, -50%) scale(1);
        border: 1px solid rgba(85, 168, 203, 0.4);
      }
    }
  }
}
.edit_current_color {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.iconpicker {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  border-radius: 3px;
  width: 250px;
  margin: -8px;
  padding: 20px;
  background: #ffffff;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: $transition_speed_fast border-color ease;
    svg {
      path {
        transition: $transition_speed_fast fill ease;
      }
    }
    &:hover {
      border: 1px solid #55a8cb;
      svg {
        path {
          fill: #506073;
        }
      }
    }
  }
}
.tippy-popper[x-placement^='left'] .currencyes-theme [x-arrow] {
  border-left-color: #4b5a6c;
}
.tippy-popper {
  max-width: 600px;
}
.currencyes-theme {
  .tippy-tooltip-content {
    margin: -8px;
  }
  ul {
    list-style-type: none;
    padding: 11px 4px 11px 4px;
    li {
      cursor: pointer;
      text-align: left;
      padding: 0px 8px;
      background-color: transparent;
      transition: $transition_speed_fast background-color ease;
      .symbol {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #edeff1;
        transition: $transition_speed_fast color ease;
      }
      .name {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #a7afb9;
        transition: $transition_speed_fast color ease;
      }
      &:hover {
        background-color: rgba(63, 75, 90, 0.2);
        .name {
          color: #448aab;
        }
        .symbol {
          color: #41acd7;
        }
      }
    }
  }
}

.total_money-theme {
  border-radius: 3px;
  padding: 10px 15px;
  background: #3f4b5a;
  .enter {
    background: #3f4b5a;
  }
  .tippy-tooltip-content {
  }
  .arrow-regular[x-arrow] {
    border-bottom: 7px solid #3f4b5a;
  }
}
.main_datepicker-theme {
  background-color: #455263;
  padding: 0;
  .arrow-regular[x-arrow] {
    border-bottom: 7px solid #455263;
  }
}
.tag-filter-theme {
  box-shadow: 0px 0px 4px rgba(59, 70, 84, 0.3);
  background-color: #5E697E;
  padding: 5px;
}
[x-placement='bottom'] .tag-filter-theme .arrow-regular[x-arrow] {
  border-bottom: 7px solid #5E697E;
}


.truncate-tooltip-theme {
  box-shadow: 0px 0px 4px rgba(59, 70, 84, 0.3);
  background-color: #697b8e;
}

[x-placement='bottom'] .truncate-tooltip-filter-theme .arrow-regular[x-arrow],
[x-placement='bottom'] .truncate-tooltip-theme .arrow-regular[x-arrow] {
  border-bottom: 7px solid #697b8e;
}
[x-placement='top'] .truncate-tooltip-filter-theme .arrow-regular[x-arrow],
[x-placement='top'] .truncate-tooltip-theme .arrow-regular[x-arrow] {
  border-top: 7px solid #697b8e;
}
.tasks-filter-theme {
  background-color: #586a7e;
}
.tasks_filter_list {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #586a7e;
}
.tasks_filter_list_all {
  .task_details_placeholder {
    &:after {
      display: none;
    }
  }
}

// плашки с чекбоксами в разделе priority filters в фильтр панели
.filter_tag_item_placeholder {
  background: #3F4B5A;
  border-radius: 3px;
  width: 85px;
  height: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  >span {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #CACFD5;
  }
  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.edit_current_currency {
  .symbol {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #edeff1;
  }
  .name {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #a7afb9;
  }
}
.check_dummy {
  width: 22px;
  height: 22px;
  border: 2px solid #545b65;
  border-radius: 50%;
  cursor: pointer;
}

.track-vertical {
  background-color: rgba(255, 255, 255, 0.1);
  width: 3px;
}
.thumb-vertical {
  background-color: rgba(255, 255, 255, 0.2);
}

.no_data_placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 460px;
}
.no_data_placeholder_img {
  margin-bottom: 50px;
}
.no_data_placeholder_title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 26px;
}
.no_data_placeholder_text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #cacfd5;
}

.integrations{
  background: #374251;
  border-radius: 3px;
  width: 100%;
  max-width: 542px;
  min-height: 324px;
  padding: 34px 19px;
  &-title{
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
  }
  &-text{
    font-size: 15px;
    line-height: 18px;
    color: #A7AFB9;
    max-width: 350px;
  }
  &-copyLinkBut{
    width: 160px;
    height: 40px;
    margin: 16px 0 22px;
    font-size: 0px;
    text-align: center;
    color: #EAEEF0;
    border: 1px solid #EDEFF1;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    transition: background 0.4s ease;
    span{
      font-family: 'Lato', arial, sans-serif;
      font-weight: 400;
      display: inline-block;
      font-size: 16px;
    }
    & > span{
      position: relative;
      top: -2px;
    }
    &:hover{
      background: #53829B;
    }
    &.-active{
      background: #55A8CB;
    }
    &:focus{
      outline: none;
    }
  }
  &-checkIcon{
    display: inline-block;
    margin-right: 3px;
  }
  &-linksBox{
    margin-top: 17px;
    font-size: 0;
  }
  &-link{
    display: inline-block;
    align-items: center;
    &::after{
      display: none;
    }
    &:not(:last-child){
      margin-right: 36px;
    }
  }
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: #000000aa;
  backdrop-filter: blur(4px);
  display: flex;
  padding: 30px;
}

.modal {
  margin: auto;
  width: 100%;
  max-width: 460px;
  background: $bg_primary;
  border-radius: 10px;
  padding: 30px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  outline: none;

  .modal-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .modal-text {
    text-align: center;

    p {
      margin: 10px 0 0;
    }
  }

  .error {
    font-size: 12px;
    margin-top: 10px;
    color: #e7483d
  }

  .modal-actions {
    display: flex;
    gap: 10px;
    margin-top: 30px;

    & > button {
      display: block;
      width: 100%;
      cursor: pointer;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      color: #fff;
      padding: 13px;
      border-radius: 5px;
      border: 0;
      box-shadow: inset 0 0 1px 1px #fff;
      outline: none;
      background: transparent;
      transition: all $transition_speed_fast;

      &.del-btn {
        background: #e7483d;
        box-shadow: none;

        &:hover {
          background: #cd2014;
        }
      }

      &:hover {
        background: $btn_color_hovered;
      }
    }
  }
}
