.task_settings_item_form {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  text-align: right;
  width: 100%;

  color: #a7afb9;
  .task_settings_item_form_hours {
    margin-right: 4px;
  }
  .task_settings_item_form_minutes {
    margin-right: 9px;
  }
  .task_settings_item_form_name {
    margin-right: 9px;
  }
  .task_settings_item_form_abs_error {
    position: absolute;
    top: 100%;
    right: 36px;
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    width: 200px;
    color: #ff0000;
  }
  div {
    display: flex;
    flex-flow: row nowrap;
  }
  input {
    padding: 3px;
    margin: 0 4px 0 0;
    background: #4b5a6c;
    border-radius: 3px;
    transition: 300ms background-color ease;
    border-width: 0;
    outline: none;
    min-width: 41px;
    width: 41px;
    max-width: 165px;
    caret-color: #58b3d8;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: right;

    color: #edeff1;
    transition: 300ms margin ease, 300ms padding ease;
    &:read-only {
      background: transparent;
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
    }
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
